<template>
  <el-cascader
    class="filter-item"
    v-model="category"
    placeholder="请选择分类"
    :props="rolesProps"
    :options="roleOptionList"
    :style="{ width: widthStyle }"
    @change="categoryChange"
    clearable
  ></el-cascader>
</template>
<script>
import { getCascadeList } from "@/api/art";
import { encapsulationMenus, splitMenus } from "@/utils/utils";

export default {
  props: {
    isRequired: {
      type: Boolean,
      default: false
    },
    isMultiple: {
      type: Boolean,
      default: false
    },
    value: {
      type: String
    },
    widthStyle: {
      type: String,
      default: "100%"
    },
    type: {
      type: String
    }
  },
  computed: {},
  components: {},
  filters: {},
  data() {
    return {
      category: splitMenus(this.value), //
      roleOptionList: [],
      rolesProps: {
        multiple: this.isMultiple, // 是否多选
        value: "id",
        label: "name",
        lazy: true,
        lazyLoad: this.cascaderLazyLoad
      }
    };
  },
  created() {
    this.getCascadeList(0);
  },
  watch: {},
  methods: {
    /**
     * 查询级联标签
     */
    getCascadeList(parentId) {
      const params = {
        parentId
      };
      getCascadeList(params).then(resp => {
        this.roleOptionList = resp.data || [];
      });
    },
    /**
     * 选中角色获取的值
     * @param node
     * @param resolve
     */
    cascaderLazyLoad(node, resolve) {
      if (!node.root) {
        const params = {
          parentId: node.value || 0
        };
        getCascadeList(params).then(resp => {
          const data = resp.data;
          resolve(data);
        });
      }
    },
    /**
     * 节点选中的值
     * @param events
     */
    categoryChange(events) {
      const resultArr = [];
      encapsulationMenus(events, resultArr);
      this.$emit("valueFunc", this.type, resultArr);
    }
  }
};
</script>
<style scoped lang="scss"></style>
